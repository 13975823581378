import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import NewButton from "../global/boton/NewButton";
function HeroVideo({ urlVideo }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 absolute justify-center bottom-[30px] md:bottom-[100px] lg:bottom-[90px] text-center text-white ">
                    <div className="md:w-[60%] w-full text-center p-4  bg-transparent rounded-sm">
                        <div className="flex flex-col py-10">
                            <h1 className="text-[30px] md:text-[3em] lg:text-[3.3em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                            <p className="px-5 md:px-[20%]">{rpdata?.dbValues?.[0].description}</p>
                            {/* <a href="https://richards-supply.renoworks.com/pages/project/gallery" className="p-5 bg2 rounded-md mx-5" >
                            Try Our Visualizer Tool
                        </a> */}
                            <NewButton />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;