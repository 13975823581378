import React, { Component } from 'react'
import { MdSearch } from 'react-icons/md'

export class NewButton extends Component {
    render() {
        return (
            <div>
                <a target='_blanck' href='https://richards-supply.renoworks.com/pages/project/gallery' className="relative group overflow-hidden inline-block border-none cursor-pointer bg-slate-500 text-gray-900 hover:text-white transition-colors duration-200 rounded-full shadow-md w-64 h-10">

                    <span className="flex items-center justify-center font-semibold relative overflow-hidden w-full h-full">
                        <span className="w-8 h-10 bg-green-500 absolute top-0 left-0 group-hover:w-full group-hover:transition-width duration-500 group-hover:shadow-md flex justify-center items-center">
                            <MdSearch className='text-white text-lg absolute right-1 group-hover:right-10 transition-transform duration-500' />

                            <p className=' text-white text-sm -translate-x-24 group-hover:translate-x-0 transition-transform duration-1000'> Show Now!</p>

                        </span>
                        <p >
                            <p className='text-white text-sm capitalize'>
                                Choose your house colors
                            </p>
                        </p>

                    </span>
                </a>
            </div>
        )
    }
}

export default NewButton